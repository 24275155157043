import Head from "next/head";
import React from "react";
import { DatesProvider } from "@mantine/dates";
import { MantineProvider } from "@mantine/core";
import { SessionWrapper } from "@/context/session";
import { StaffWrapper } from "@/context/staff";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "dayjs/locale/uk";
import "@mantine/core/styles.css";
import "@mantine/dates/styles.css";
import "@mantine/charts/styles.css";
import "@/styles/global.scss";
import { HelpsWrapper } from "@/context/helps";

type AppProps = {
  Component: React.FC;
  pageProps: any;
};

const App: React.FC<AppProps> = ({ Component, pageProps }) => {
  return (
    <MantineProvider>
      <DatesProvider
        settings={{
          locale: "uk",
        }}
      >
        <SessionWrapper>
          <StaffWrapper>
            <HelpsWrapper>
              <Head>
                <link
                  rel="apple-touch-icon"
                  sizes="180x180"
                  href="/apple-touch-icon.png"
                />
                <link
                  rel="icon"
                  type="image/png"
                  sizes="32x32"
                  href="/favicon-32x32.png"
                />
                <link
                  rel="icon"
                  type="image/png"
                  sizes="16x16"
                  href="/favicon-16x16.png"
                />
                <link rel="manifest" href="/site.webmanifest" />
                <link
                  rel="mask-icon"
                  href="/safari-pinned-tab.svg"
                  color="#5bbad5"
                />
                <meta name="msapplication-TileColor" content="#603cba" />
                <meta name="theme-color" content="#5661c6" />
              </Head>
              <Component {...pageProps} />
              <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
              />
            </HelpsWrapper>
          </StaffWrapper>
        </SessionWrapper>
      </DatesProvider>
    </MantineProvider>
  );
};
export default App;
