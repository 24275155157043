import axios, { AxiosRequestConfig } from "axios";
import getConfig from "next/config";
import { createContext, ReactNode, useContext, useState } from "react";
import { getCookie } from "cookies-next";

const {
  publicRuntimeConfig: { API_URL },
} = getConfig();

const AppContext = createContext<any>({
  session: null,
  initSession: () => {},
  setSession: () => {},
});

type Props = {
  children: ReactNode;
};

export const StaffWrapper = ({ children }: Props) => {
  const setSession = (bs: string | null) => {
    setState({ ...state, session: bs });
  };

  const initSession = async () => {
    const institution = getCookie("mafi_institution_token");
    const headers = {
      Authorization: `Bearer ${getCookie("mafi_staff_token")}`,
      Institution: institution,
    };

    const fetchObj: AxiosRequestConfig = {
      method: `GET`,
      url: API_URL + `/api/session/staff`,
      headers: headers,
    };

    await axios(fetchObj)
      .then(({ data }: any) => {
        setSession(data);
      })
      .catch((err: any) => {
        setSession(null);
      });
  };

  const initState = {
    session: null,
    initSession: initSession,
    setSession: setSession,
  };

  const [state, setState] = useState<any>(initState);

  return <AppContext.Provider value={state}>{children}</AppContext.Provider>;
};

export function useStaff() {
  return useContext(AppContext);
}
