import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

const AppContext = createContext<any>({
  help: null,
  setHelp: () => {},
});

type Props = {
  children: ReactNode;
};

export const HelpsWrapper = ({ children }: Props) => {
  const helpBoxRef = useRef<any>(null);

  const setHelp = (h: any) => {
    setState({ ...state, help: h });
  };

  const initState = {
    help: null,
    setHelp: setHelp,
  };

  const [state, setState] = useState<any>(initState);

  const handleClickOutside = (event: any) => {
    if (helpBoxRef.current && !helpBoxRef.current.contains(event.target)) {
      setHelp(null);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <AppContext.Provider value={state}>
      {children}
      {state.help && (
        <div className={"help-modal"} ref={helpBoxRef}>
          <h2>{state?.help.title}</h2>
          {state?.help?.use_video && state?.help?.video_widget && (
            <div
              className={"help-video"}
              dangerouslySetInnerHTML={{
                __html: state.help.video_widget,
              }}
            ></div>
          )}
          <div
            className={"help-content"}
            dangerouslySetInnerHTML={{
              __html: state.help.value || "Інформація відсутня",
            }}
          ></div>
        </div>
      )}
    </AppContext.Provider>
  );
};

export function useHelps() {
  return useContext(AppContext);
}
